.homeContainer {
    /* width: 100%; */
    height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
        top: 60px;
    bottom: 0;
    right: 0px;
    left: 225px;
    /* overflow-y: auto; */
    position: absolute !important;
    padding: 20px 30px 30px 30px;

}
.homeHeader {
    margin-top: 5px;
    background-color: tomato;
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 5px lightgrey;
    margin-bottom: 5px;
}

.totalTextcontainer {
    height: 100%;
    /* background-color: yellow; */
    position: absolute;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.followUpContainer {
    height: 100%;
    width: 50%;
    /* background-color: rebeccapurple; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.followUpElement {
    height: 60%;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    border: white solid 2px;
}
.homeTableContainer {
    width: 100%;
    height: 80vh;
    display: flex;
    overflow: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.filter-container{
    width: 100% !important;
    margin: 2% 0%;
}

.btn-filter-reset{
    margin: 10% !important;
}
.filter-header{
    font-weight: bolder !important;
    text-align: center !important;
}
.homeTotalText {
    color: white;
    font-size: 20;
    font-weight: 700;
}

.navbar {
    width: 100%;
    color: white;
    background: tomato;
    box-shadow: 5px 5px 5px lightgrey;
}

  .homeContainer .homeHeader .follow-up-nav-container {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: var(--themeColor); }
    .homeContainer .homeHeader .follow-up-nav-container .follow-up-text {
      color: #fff;
      font-size: 20px;
      margin: 10px 15px; }
    .homeContainer .homeHeader .follow-up-nav-container .count {
      color: #fff;
      margin: 10px;
      padding: 5px 10px;
      border-radius: 5px;
      border: 2px solid #fff; }
    .homeContainer .homeHeader .follow-up-nav-container .follow-up-button {
      padding: 5px 10px;
      background: #fff !important;
      color: var(--themeColor) !important; }

    #multiselectContainerReact{
      width: 85%;
      margin-left: 15%;
    }