.design-center-button{
    border: 2px solid #ef5920;
    color:#ef5920;
    margin-top: 5rem;
    padding : 10px 25px 10px 25px; 
    background-color: white;
    border-radius: 25px;    
}
.design-center-button:hover{
    background-color: #f0784a;
    color:white;
}

.control-label {
    font-weight: bold;
    font-size: 15px;
    margin-top: 15px;
    color: #6f6f6f;
    letter-spacing: 0.1rem;
    display: block; }

.mandatory{
    color: red;
}

input{
    border: 1px solid gray;
}
input:hover{
    border:1px solid #40a9ff;
}
input:active{
    border:1px solid #096dd9;
}

*:focus {
    outline: 0 !important;
}
.error-message p{
    color: red;
}
.remove-error{
    border:none;
}

@media screen and (max-width: 992px) {
    input,Select,Option {
      width: 50%;
    }
  }

